import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';

import { useUser } from '@travelity/api';
import { LoadingOverlay } from '@travelity/ui';
import Header from './header';
import Sidebar from './sidebar';

import LogoHeader from './logo-header';
import { RegisterSteps } from '../../views/register-steps';
import UserProvider from '../../contexts/user/user-provider';

const drawerWidth = 224;

type ContextType = {
    mainIsLoading: boolean;
    setMainIsLoading: (v: boolean) => void;
};

function MainLayout() {
    const theme = useTheme();
    const xl = useMediaQuery('(min-width: 1450px)');
    const defaultDrawerState = !!xl;
    // Handle left drawer
    const [leftDrawerOpened, setLeftDrawerOpened] = useState<boolean>();
    const showOpened =
        leftDrawerOpened !== undefined ? leftDrawerOpened : defaultDrawerState;
    const handleLeftDrawerToggle = useCallback(() => {
        setLeftDrawerOpened(
            prevState =>
                !(prevState !== undefined ? prevState : defaultDrawerState)
        );
    }, [defaultDrawerState]);

    const [mainIsLoading, setMainIsLoading] = useState(false);
    const context = useMemo(
        () => ({ mainIsLoading, setMainIsLoading }),
        [mainIsLoading]
    );

    const { data: user, isLoading: isUserLoading } = useUser();
    const profileIsComplete = !!user;

    // useEffect(() => {
    //     if (!isUserLoading && !user) {
    //         navigate('/register');
    //     }
    // }, [isUserLoading, user]);

    // if (isUserLoading) return null;

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                className="hide-on-print"
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderBottom: '1px solid #E7E8F2',
                    bgcolor: '#FFFFFF',
                }}
            >
                {!isUserLoading && (
                    <Toolbar sx={{ '&&&': { pl: 1 } }} variant="dense">
                        {profileIsComplete ? (
                            <Header
                                handleLeftDrawerToggle={handleLeftDrawerToggle}
                                user={user}
                            />
                        ) : (
                            <LogoHeader />
                        )}
                    </Toolbar>
                )}
            </AppBar>

            {/* drawer */}
            {profileIsComplete ? (
                <>
                    <Sidebar
                        user={user}
                        drawerOpen={showOpened}
                        drawerToggle={handleLeftDrawerToggle}
                    />

                    {/* main content */}
                    <Box
                        component="main"
                        sx={{
                            height: 'calc(100vh - 60px)',
                            bgcolor: '#F4F6FA',
                            mt: '60px',
                            overflow: 'hidden',
                            ...theme.typography.body1,
                            ...(!showOpened
                                ? {
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      width: 'calc(100% + 16px)',
                                      transition: theme.transitions.create(
                                          'margin',
                                          {
                                              easing: theme.transitions.easing
                                                  .sharp,
                                              duration:
                                                  theme.transitions.duration
                                                      .leavingScreen,
                                          }
                                      ),
                                      // [theme.breakpoints.up('md')]: {
                                      //     marginLeft: `${-(drawerWidth - 20)}px`,
                                      //     width: `calc(100% - ${drawerWidth}px)`,
                                      // },
                                      // [theme.breakpoints.down('md')]: {
                                      //     marginLeft: '20px',
                                      //     width: `calc(100% - ${drawerWidth}px)`,
                                      //     padding: '16px',
                                      // },
                                      // [theme.breakpoints.down('sm')]: {
                                      //     marginLeft: '10px',
                                      //     width: `calc(100% - ${drawerWidth}px)`,
                                      //     padding: '16px',
                                      //     marginRight: '10px',
                                      // },
                                      marginLeft: `${-drawerWidth - 16}px`,
                                  }
                                : {
                                      transition: theme.transitions.create(
                                          'margin',
                                          {
                                              easing: theme.transitions.easing
                                                  .easeOut,
                                              duration:
                                                  theme.transitions.duration
                                                      .enteringScreen,
                                          }
                                      ),
                                      marginLeft: `0`,
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      // width: `calc(100% - ${drawerWidth}px)`,
                                      // width: '100%',

                                      width: 'calc(100% + 16px)',

                                      [theme.breakpoints.down('md')]: {
                                          marginLeft: '20px',
                                      },
                                      [theme.breakpoints.down('sm')]: {
                                          marginLeft: '10px',
                                      },
                                  }),
                        }}
                    >
                        <UserProvider user={user}>
                            <Outlet context={context} />
                            {context.mainIsLoading && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}
                        </UserProvider>
                    </Box>
                </>
            ) : !isUserLoading ? (
                <Box
                    component="main"
                    sx={{
                        height: 'calc(100vh - 60px)',
                        bgcolor: '#F4F6FA',
                        mt: '60px',
                        width: 1,
                        overflow: 'hidden',
                        ...theme.typography.body1,
                    }}
                >
                    <RegisterSteps user={user} />
                </Box>
            ) : (
                <LoadingOverlay
                    title="Preparing your environment."
                    subTitle="It’ll just take a moment."
                />
            )}
        </Box>
    );
}

export function useLayoutContext() {
    return useOutletContext<ContextType>();
}

export default MainLayout;
